import React, { useState, useEffect } from 'react';
import { TabsContent } from '@/components/ui/tabs';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Loader2, Search } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/hooks/useAuth';
import { toast } from 'sonner';
import { Database } from '@/types/supabase';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

type Message = Database['public']['Tables']['messages']['Row'] & {
  phone_numbers: {
    number: string;
  } | null;
};

interface SentMessage {
  id: string;
  content: string;
  recipient: string;
  sent_at: string;
  status: string;
  cost: number;
  from_number: string;
  sent_at_raw: string;
}

const SentMessagesTab = () => {
  const { user } = useAuth();
  const [messages, setMessages] = useState<SentMessage[]>([]);
  const [filteredMessages, setFilteredMessages] = useState<SentMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateFilter, setDateFilter] = useState('all');

  useEffect(() => {
    const fetchMessages = async () => {
      if (!user) return;

      try {
        const { data: messagesData, error: messagesError } = await supabase
          .from('messages')
          .select('*, phone_numbers!inner(number)')
          .eq('user_id', user.id)
          .order('sent_at', { ascending: false })
          .limit(50);

        if (messagesError) throw messagesError;

        const formattedMessages: SentMessage[] = (messagesData as Message[]).map(msg => ({
          id: msg.id,
          content: msg.content,
          recipient: msg.recipient,
          sent_at: new Date(msg.sent_at).toLocaleString(),
          sent_at_raw: msg.sent_at,
          status: msg.status,
          cost: msg.cost,
          from_number: msg.phone_numbers?.number || 'Unknown'
        }));

        setMessages(formattedMessages);
        setFilteredMessages(formattedMessages);
      } catch (error: unknown) {
        console.error('Error fetching messages:', error);
        toast.error('Failed to load sent messages');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMessages();
  }, [user]);

  useEffect(() => {
    let filtered = [...messages];

    // Apply date filter
    if (dateFilter !== 'all') {
      const now = new Date();
      const filterDate = new Date();
      
      switch (dateFilter) {
        case 'today':
          filterDate.setHours(0, 0, 0, 0);
          break;
        case 'week':
          filterDate.setDate(now.getDate() - 7);
          break;
        case 'month':
          filterDate.setMonth(now.getMonth() - 1);
          break;
      }

      filtered = filtered.filter(msg => new Date(msg.sent_at_raw) >= filterDate);
    }

    // Apply search filter
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(msg =>
        msg.from_number.toLowerCase().includes(query) ||
        msg.recipient.toLowerCase().includes(query) ||
        msg.content.toLowerCase().includes(query)
      );
    }

    setFilteredMessages(filtered);
  }, [searchQuery, dateFilter, messages]);

  if (isLoading) {
    return (
      <TabsContent value="sent-messages" className="py-12 text-center">
        <Loader2 className="h-8 w-8 mx-auto animate-spin text-blue-500" />
        <p className="mt-4 text-gray-500">Loading your sent messages...</p>
      </TabsContent>
    );
  }

  return (
    <TabsContent value="sent-messages">
      <Card>
        <CardHeader>
          <CardTitle>Sent Messages</CardTitle>
          <div className="flex gap-4 mt-4">
            <div className="relative flex-1">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search messages..."
                className="pl-8"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <Select value={dateFilter} onValueChange={setDateFilter}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filter by date" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All time</SelectItem>
                <SelectItem value="today">Today</SelectItem>
                <SelectItem value="week">Last 7 days</SelectItem>
                <SelectItem value="month">Last 30 days</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>From</TableHead>
                <TableHead>To</TableHead>
                <TableHead>Message</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Cost</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredMessages.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center text-gray-500">
                    {messages.length === 0 ? 'No messages sent yet' : 'No messages match your filters'}
                  </TableCell>
                </TableRow>
              ) : (
                filteredMessages.map((message) => (
                  <TableRow key={message.id}>
                    <TableCell>{message.sent_at}</TableCell>
                    <TableCell>{message.from_number}</TableCell>
                    <TableCell>{message.recipient}</TableCell>
                    <TableCell className="max-w-xs truncate">{message.content}</TableCell>
                    <TableCell>
                      <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                        message.status === 'delivered' ? 'bg-green-100 text-green-800' :
                        message.status === 'failed' ? 'bg-red-100 text-red-800' :
                        'bg-yellow-100 text-yellow-800'
                      }`}>
                        {message.status}
                      </span>
                    </TableCell>
                    <TableCell>£{message.cost.toFixed(2)}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </TabsContent>
  );
};

export default SentMessagesTab; 