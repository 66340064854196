import React from 'react';
import { TabsContent } from '@/components/ui/tabs';
import MessageForm from '@/components/MessageForm';

interface PhoneNumber {
  id: string;
  number: string;
}

interface MessageFormTabProps {
  phoneNumbers: PhoneNumber[];
  selectedPhoneNumberId?: string;
}

const MessageFormTab = ({ phoneNumbers, selectedPhoneNumberId }: MessageFormTabProps) => {
  return (
    <TabsContent value="messages">
      <div className="max-w-2xl mx-auto">
        <MessageForm 
          phoneNumbers={phoneNumbers} 
          selectedPhoneNumberId={selectedPhoneNumberId}
        />
      </div>
    </TabsContent>
  );
};

export default MessageFormTab;
