export function formatUKPhoneNumber(phoneNumber: string): string {
  // Remove any non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Format based on number type
  if (cleaned.startsWith('07')) {
    // Mobile numbers: 07XXX XXX XXX
    return cleaned.replace(/(\d{5})(\d{3})(\d{3})/, '$1 $2 $3');
  } else if (cleaned.startsWith('0800')) {
    // Freephone numbers: 0800 XXX XXXX
    return cleaned.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
  } else if (cleaned.startsWith('01202')) {
    // Local numbers: 01202 XXX XXX
    return cleaned.replace(/(\d{5})(\d{3})(\d{3})/, '$1 $2 $3');
  }
  
  // Default format for other numbers
  return cleaned.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3');
} 