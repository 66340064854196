
import React from 'react';
import Navbar from '@/components/Navbar';
import { Shield } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <div className="flex items-center justify-center mb-8">
            <div className="p-2 bg-blue-100 rounded-full">
              <Shield className="h-8 w-8 text-blue-600" />
            </div>
          </div>
          
          <h1 className="text-4xl font-bold text-center mb-8">Privacy Policy</h1>
          <div className="bg-white p-8 rounded-lg shadow-sm">
            <div className="prose prose-blue max-w-none">
              <p className="text-gray-600">Last updated: July 1, 2023</p>
              
              <h2>Introduction</h2>
              <p>
                EasySMS ("we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.
              </p>
              <p>
                Please read this Privacy Policy carefully. By accessing or using our service, you acknowledge that you have read, understood, and agree to be bound by all the terms of this Privacy Policy.
              </p>
              
              <h2>Information We Collect</h2>
              <h3>Personal Information</h3>
              <p>
                We may collect personal information that you voluntarily provide to us when you:
              </p>
              <ul>
                <li>Register for an account</li>
                <li>Purchase a phone number</li>
                <li>Send messages through our service</li>
                <li>Contact our customer service</li>
                <li>Sign up for our newsletter</li>
              </ul>
              <p>
                This information may include your name, email address, phone number, billing information, and any other information you choose to provide.
              </p>
              
              <h3>Usage Information</h3>
              <p>
                We automatically collect certain information about how you interact with our services, including:
              </p>
              <ul>
                <li>IP address</li>
                <li>Device information</li>
                <li>Browser type</li>
                <li>Pages visited</li>
                <li>Time spent on pages</li>
                <li>Referring website</li>
                <li>Other browsing information</li>
              </ul>
              
              <h2>How We Use Your Information</h2>
              <p>
                We may use the information we collect for various purposes, including:
              </p>
              <ul>
                <li>Providing, maintaining, and improving our services</li>
                <li>Processing transactions and sending related information</li>
                <li>Responding to your requests and inquiries</li>
                <li>Sending administrative messages, updates, and security alerts</li>
                <li>Sending marketing communications (with your consent)</li>
                <li>Personalizing your experience</li>
                <li>Monitoring and analyzing usage patterns and trends</li>
                <li>Protecting against fraudulent or unauthorized activity</li>
              </ul>
              
              <h2>How We Share Your Information</h2>
              <p>
                We may share your information with:
              </p>
              <ul>
                <li>Service providers who perform services on our behalf</li>
                <li>Payment processors to complete transactions</li>
                <li>Telecommunications partners who facilitate message delivery</li>
                <li>Professional advisors, such as lawyers and accountants</li>
                <li>Third parties in connection with a business transfer</li>
                <li>Law enforcement or other governmental authorities in response to a legal request</li>
              </ul>
              
              <h2>Data Security</h2>
              <p>
                We implement appropriate technical and organizational measures to protect the security of your personal information. However, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure.
              </p>
              
              <h2>International Data Transfers</h2>
              <p>
                Your information may be transferred to and processed in countries other than the country in which you reside. These countries may have data protection laws that are different from the laws of your country.
              </p>
              
              <h2>Your Rights</h2>
              <p>
                Depending on your location, you may have certain rights regarding your personal information, such as:
              </p>
              <ul>
                <li>The right to access and receive a copy of your personal information</li>
                <li>The right to rectify or update your personal information</li>
                <li>The right to erase your personal information</li>
                <li>The right to restrict or object to our processing of your personal information</li>
                <li>The right to data portability</li>
                <li>The right to withdraw consent</li>
              </ul>
              
              <h2>Changes to This Privacy Policy</h2>
              <p>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.
              </p>
              
              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please contact us at:
              </p>
              <p>
                Email: privacy@EasySMS.com<br />
                Address: 123 Tech Street, London, UK
              </p>
            </div>
          </div>
          
          <div className="mt-8 text-center">
            <Link to="/">
              <Button variant="outline">Return to Homepage</Button>
            </Link>
          </div>
        </div>
      </main>
      
      <footer className="py-8 bg-gray-900 text-white mt-16">
        <div className="container px-4 mx-auto">
          <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} EasySMS. All rights reserved.
            </div>
            <div className="flex space-x-6">
              <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                Privacy
              </Link>
              <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                Terms
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Privacy;
