import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const isAuthenticated = false; // Replace with actual auth state

  // Add scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId: string) => {
    // Only scroll if we're on the home page
    if (location.pathname !== '/') {
      window.location.href = `/#${sectionId}`;
      return;
    }
    
    const element = document.getElementById(sectionId);
    if (element) {
      // Add offset for the fixed navbar height
      const navbarHeight = 80; // Approximate height of the navbar
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
      
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 px-6 py-4 transition-all duration-300 ${
        isScrolled ? 'bg-white/80 backdrop-blur-md shadow-sm' : 'bg-transparent'
      }`}
    >
      <div className="container flex items-center justify-between">
        <Link to="/" className="flex items-center space-x-2">
          <span className="text-xl font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-700">
            EasySMS
          </span>
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6">
          <Link
            to="/"
            className={`text-sm transition-colors hover:text-primary ${
              location.pathname === '/' ? 'text-primary font-medium' : 'text-foreground/80'
            }`}
          >
            Home
          </Link>
          <button
            onClick={() => scrollToSection('features')}
            className="text-sm text-foreground/80 transition-colors hover:text-primary"
          >
            Features
          </button>
          <button
            onClick={() => scrollToSection('pricing')}
            className="text-sm text-foreground/80 transition-colors hover:text-primary"
          >
            Pricing
          </button>
          
          {isAuthenticated ? (
            <Link to="/dashboard">
              <Button variant="ghost" className="rounded-full">
                Dashboard
              </Button>
            </Link>
          ) : (
            <div className="flex space-x-2">
              <Link to="/auth?type=login">
                <Button variant="ghost" className="rounded-full">
                  Log in
                </Button>
              </Link>
              <Link to="/auth?type=signup">
                <Button className="rounded-full bg-primary hover:bg-primary/90 text-white">
                  Sign up
                </Button>
              </Link>
            </div>
          )}
        </div>

        {/* Mobile menu button */}
        <button className="md:hidden" onClick={toggleMenu}>
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-full left-0 right-0 bg-white/95 backdrop-blur-lg shadow-md animate-in border-t">
          <div className="container flex flex-col space-y-4 py-6">
            <Link
              to="/"
              className={`text-base transition-colors hover:text-primary px-4 py-2 ${
                location.pathname === '/' ? 'text-primary font-medium' : 'text-foreground/80'
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </Link>
            <button
              onClick={() => {
                scrollToSection('features');
                setIsMenuOpen(false);
              }}
              className="text-base text-foreground/80 transition-colors hover:text-primary px-4 py-2 text-left"
            >
              Features
            </button>
            <button
              onClick={() => {
                scrollToSection('pricing');
                setIsMenuOpen(false);
              }}
              className="text-base text-foreground/80 transition-colors hover:text-primary px-4 py-2 text-left"
            >
              Pricing
            </button>
            
            {isAuthenticated ? (
              <Link
                to="/dashboard"
                className="text-base text-foreground/80 transition-colors hover:text-primary px-4 py-2"
                onClick={() => setIsMenuOpen(false)}
              >
                Dashboard
              </Link>
            ) : (
              <>
                <Link
                  to="/auth?type=login"
                  className="text-base text-foreground/80 transition-colors hover:text-primary px-4 py-2"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Log in
                </Link>
                <Link to="/auth?type=signup" onClick={() => setIsMenuOpen(false)}>
                  <Button className="w-full rounded-full">Sign up</Button>
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
