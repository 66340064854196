import React from 'react';
import Navbar from '@/components/Navbar';
import Hero from '@/components/Hero';
import Features from '@/components/Features';
import Footer from '@/components/Footer';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { MessageSquare, Phone, Shield } from 'lucide-react';

const Index = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      
      <main>
        <Hero />
        <Features />
        
        {/* How it works */}
        <section className="py-20 bg-white">
          <div className="container px-4 mx-auto">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-semibold mb-4">How It Works</h2>
              <p className="text-xl text-gray-600">
                Get started with EasySMS in just a few simple steps
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mb-6">
                  <Shield className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-medium mb-3">1. Create an Account</h3>
                <p className="text-gray-600 mb-5">Sign up for an account in less than 60 seconds. No credit card required to get started.</p>
                <div className="mt-auto">
                  <Link to="/auth?type=signup">
                    <Button variant="outline" className="rounded-full">Get Started</Button>
                  </Link>
                </div>
              </div>
              
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mb-6">
                  <Phone className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-medium mb-3">2. Select a Number</h3>
                <p className="text-gray-600 mb-5">Choose from our selection of UK mobile, freephone, and local area code numbers.</p>
                <div className="mt-auto">
                  <Link to="/auth?type=signup">
                    <Button variant="outline" className="rounded-full">View Numbers</Button>
                  </Link>
                </div>
              </div>
              
              <div className="flex flex-col items-center text-center">
                <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center mb-6">
                  <MessageSquare className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-xl font-medium mb-3">3. Start Messaging</h3>
                <p className="text-gray-600 mb-5">Add £20 credit to your account, get 2 months free, and start sending messages at just 10p per SMS.</p>
                <div className="mt-auto">
                  <Link to="/auth?type=signup">
                    <Button variant="outline" className="rounded-full">Learn More</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* CTA */}
        <section className="py-20 bg-blue-600 text-white">
          <div className="container px-4 mx-auto">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl md:text-4xl font-semibold mb-6">Ready to Get Started?</h2>
              <p className="text-xl text-blue-100 mb-8">
                Top up £20 today and get your first two months line rental completely FREE!
              </p>
              <Link to="/auth?type=signup">
                <Button size="lg" className="rounded-full bg-white text-blue-600 hover:bg-blue-50 h-12 px-8">
                  Create Your Account
                </Button>
              </Link>
            </div>
          </div>
        </section>
        
        <Footer />
      </main>
    </div>
  );
};

export default Index;