import { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { Session, User } from '@supabase/supabase-js';
import { toast } from 'sonner';

interface UserProfile {
  id: string;
  full_name: string;
  company_name: string;
  balance: number;
  created_at: string;
  updated_at: string;
}

interface AuthContextType {
  session: Session | null;
  user: User | null;
  profile: UserProfile | null;
  loading: boolean;
  error: Error | null;
  signOut: () => Promise<void>;
  refreshProfile: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  // Function to fetch user profile
  const fetchUserProfile = async (userId: string): Promise<void> => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;
      setProfile(data as UserProfile);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setProfile(null);
    }
  };

  // Function to update auth state
  const updateAuthState = async (newSession: Session | null) => {
    try {
      // If we're updating to the same user, just update loading state
      if (newSession?.user?.id === user?.id) {
        setLoading(false);
        return;
      }

      // Update basic auth state immediately
      setSession(newSession);
      setUser(newSession?.user ?? null);

      // Fetch profile if we have a user
      if (newSession?.user) {
        await fetchUserProfile(newSession.user.id);
      } else {
        setProfile(null);
      }
    } catch (err) {
      console.error('Error updating auth state:', err);
      setError(err instanceof Error ? err : new Error('Failed to update auth state'));
    } finally {
      setLoading(false);
    }
  };

  // Function to manually refresh profile
  const refreshProfile = async () => {
    if (user?.id) {
      await fetchUserProfile(user.id);
    }
  };

  // Initialize auth and set up listeners
  useEffect(() => {
    let mounted = true;

    // Function to safely update state only if component is mounted
    const safeUpdateState = async (session: Session | null) => {
      if (!mounted) return;
      await updateAuthState(session);
    };

    // Initial session check and setup auth state listener
    const initialize = async () => {
      try {
        // Get initial session
        const { data: { session } } = await supabase.auth.getSession();
        await safeUpdateState(session);

        // Listen for auth changes
        const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, newSession) => {
          if (!mounted) return;

          if (event === 'SIGNED_OUT') {
            setSession(null);
            setUser(null);
            setProfile(null);
            setLoading(false);
          } else if (event === 'SIGNED_IN') {
            await safeUpdateState(newSession);
          } else if (event === 'TOKEN_REFRESHED') {
            // For token refresh, just update the session without loading state
            setSession(newSession);
          }
        });

        return () => {
          subscription.unsubscribe();
        };
      } catch (err) {
        console.error('Error initializing auth:', err);
        if (mounted) {
          setError(err instanceof Error ? err : new Error('Failed to initialize auth'));
          setLoading(false);
        }
      }
    };

    // Start initialization
    const cleanup = initialize();

    // Cleanup function
    return () => {
      mounted = false;
      cleanup.then(unsubscribe => unsubscribe?.());
    };
  }, []); // Empty dependency array since this should only run once

  // Reset error when auth state changes
  useEffect(() => {
    if (user || !loading) {
      setError(null);
    }
  }, [user, loading]);

  const signOut = async () => {
    try {
      setLoading(true);
      await supabase.auth.signOut();
      setSession(null);
      setUser(null);
      setProfile(null);
      toast.success('Signed out successfully');
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to sign out');
    } finally {
      setLoading(false);
    }
  };

  const value = useMemo(() => ({
    session,
    user,
    profile,
    loading,
    error,
    signOut,
    refreshProfile
  }), [session, user, profile, loading, error]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
