import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { toast } from 'sonner';
import { Key, Copy, Trash2, Plus, AlertCircle } from 'lucide-react';
import { CopyButton } from './CopyButton';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/hooks/useAuth';
import { Loader2 } from 'lucide-react';

interface ApiKey {
  id: string;
  name: string;
  key: string;
  created_at: string;
  last_used_at: string | null;
}

interface ApiError {
  message: string;
}

const ApiKeysManager = () => {
  const { user } = useAuth();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [newKeyName, setNewKeyName] = useState('');
  const [showNewKey, setShowNewKey] = useState(false);
  const [newKeyValue, setNewKeyValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingKey, setIsCreatingKey] = useState(false);

  // Fetch API keys on component mount
  useEffect(() => {
    const fetchApiKeys = async () => {
      if (!user) return;
      
      try {
        const { data, error } = await supabase
          .from('api_keys')
          .select('*')
          .eq('user_id', user.id)
          .eq('is_active', true);
          
        if (error) throw error;
        
        setApiKeys(data || []);
      } catch (error) {
        console.error('Error fetching API keys:', error);
        toast.error('Failed to load API keys');
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchApiKeys();
  }, [user]);

  const handleCreateKey = async () => {
    if (!user) {
      toast.error('You must be logged in to create API keys');
      return;
    }
    
    setIsCreatingKey(true);
    
    try {
      // First, get a secure random API key
      const { data: keyData, error: keyError } = await supabase
        .rpc('generate_api_key');
        
      if (keyError) throw keyError;
      
      // Now store it in the database
      const { data, error } = await supabase
        .from('api_keys')
        .insert({
          user_id: user.id,
          name: newKeyName || `API Key ${apiKeys.length + 1}`,
          key: keyData
        })
        .select()
        .single();
        
      if (error) throw error;
      
      // Show the new key
      setNewKeyValue(keyData);
      setApiKeys([...apiKeys, data]);
      setNewKeyName('');
      setShowNewKey(true);
      setIsDialogOpen(false);
      
      toast.success('API key created successfully');
    } catch (error: unknown) {
      console.error('Error creating API key:', error);
      const apiError = error as ApiError;
      toast.error(apiError.message || 'Failed to create API key');
    } finally {
      setIsCreatingKey(false);
    }
  };

  const handleDeleteKey = async (id: string) => {
    if (!user) return;
    
    try {
      // We don't actually delete the key, just mark it as inactive
      const { error } = await supabase
        .from('api_keys')
        .update({ is_active: false })
        .eq('id', id)
        .eq('user_id', user.id);
        
      if (error) throw error;
      
      // Update local state
      setApiKeys(apiKeys.filter(key => key.id !== id));
      
      toast.success('API key deleted successfully');
    } catch (error: unknown) {
      console.error('Error deleting API key:', error);
      const apiError = error as ApiError;
      toast.error(apiError.message || 'Failed to delete API key');
    }
  };

  // Format the last used time in a readable format
  const formatLastUsed = (lastUsed: string | null) => {
    if (!lastUsed) return 'Never';
    
    const date = new Date(lastUsed);
    const now = new Date();
    const diffMs = now.getTime() - date.getTime();
    const diffMins = Math.round(diffMs / 60000);
    const diffHours = Math.round(diffMs / 3600000);
    const diffDays = Math.round(diffMs / 86400000);
    
    if (diffMins < 60) {
      return `${diffMins} minute${diffMins !== 1 ? 's' : ''} ago`;
    }
    if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
    }
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  };

  // Format API key for display
  const formatKeyForDisplay = (key: string) => {
    const prefix = key.substring(0, 6);
    return `${prefix}••••••••••••••••••`;
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        <span className="ml-2">Loading API keys...</span>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-medium">API Keys</h3>
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="mr-2 h-4 w-4" />
              Create API Key
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Create New API Key</DialogTitle>
              <DialogDescription>
                Generate a new API key for integrating with our service.
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-4 py-4">
              <div className="space-y-2">
                <label htmlFor="key-name" className="text-sm font-medium">
                  Key Name
                </label>
                <Input
                  id="key-name"
                  placeholder="e.g., Production, Testing"
                  value={newKeyName}
                  onChange={(e) => setNewKeyName(e.target.value)}
                />
                <p className="text-sm text-gray-500">
                  Give your key a memorable name to help you identify it later.
                </p>
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" onClick={() => setIsDialogOpen(false)} disabled={isCreatingKey}>
                Cancel
              </Button>
              <Button onClick={handleCreateKey} disabled={isCreatingKey}>
                {isCreatingKey ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Generating
                  </>
                ) : (
                  'Generate Key'
                )}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      {showNewKey && (
        <div className="bg-green-50 border border-green-100 rounded-lg p-4 mb-6">
          <div className="flex items-start space-x-3">
            <AlertCircle className="h-5 w-5 text-green-500 mt-0.5" />
            <div className="flex-1">
              <h4 className="font-medium text-green-800">New API Key Created</h4>
              <p className="text-sm text-green-700 mb-3">
                This key will only be displayed once. Please copy it now and store it securely.
              </p>
              <div className="bg-white p-3 rounded border border-green-200 flex items-center">
                <code className="flex-1 font-mono text-sm text-gray-800 truncate">
                  {newKeyValue}
                </code>
                <CopyButton textToCopy={newKeyValue} />
              </div>
              <div className="mt-3">
                <Button variant="outline" size="sm" onClick={() => setShowNewKey(false)}>
                  I've saved my key
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden">
        <div className="divide-y divide-gray-200">
          {apiKeys.map((key) => (
            <div key={key.id} className="p-4 hover:bg-gray-50 transition-colors">
              <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                <div className="flex items-center space-x-3">
                  <div className="p-2 bg-blue-50 rounded-full text-blue-600">
                    <Key className="h-5 w-5" />
                  </div>
                  <div>
                    <h4 className="font-medium">{key.name}</h4>
                    <p className="text-sm text-gray-500">
                      {formatKeyForDisplay(key.key)}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
                  <div className="text-sm text-gray-500 text-right">
                    <p>Created: {new Date(key.created_at).toLocaleDateString()}</p>
                    <p>Last used: {formatLastUsed(key.last_used_at)}</p>
                  </div>
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleDeleteKey(key.id)}
                    className="hover:bg-red-50 hover:text-red-600 hover:border-red-200"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          ))}
          
          {apiKeys.length === 0 && (
            <div className="p-8 text-center">
              <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-50 text-blue-600 mb-4">
                <Key className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-medium mb-2">No API Keys</h3>
              <p className="text-gray-500 mb-4">
                Create your first API key to start integrating with our service.
              </p>
              <Dialog>
                <DialogTrigger asChild>
                  <Button>Create API Key</Button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Create New API Key</DialogTitle>
                    <DialogDescription>
                      Generate a new API key for integrating with our service.
                    </DialogDescription>
                  </DialogHeader>
                  <div className="space-y-4 py-4">
                    <div className="space-y-2">
                      <label htmlFor="key-name" className="text-sm font-medium">
                        Key Name
                      </label>
                      <Input
                        id="key-name"
                        placeholder="e.g., Production, Testing"
                        value={newKeyName}
                        onChange={(e) => setNewKeyName(e.target.value)}
                      />
                      <p className="text-sm text-gray-500">
                        Give your key a memorable name to help you identify it later.
                      </p>
                    </div>
                  </div>
                  <DialogFooter>
                    <Button variant="outline" onClick={() => setIsDialogOpen(false)} disabled={isCreatingKey}>
                      Cancel
                    </Button>
                    <Button onClick={handleCreateKey} disabled={isCreatingKey}>
                      {isCreatingKey ? (
                        <>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Generating
                        </>
                      ) : (
                        'Generate Key'
                      )}
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </div>
          )}
        </div>
      </div>

      <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <AlertCircle className="h-5 w-5 text-blue-500" />
          </div>
          <div>
            <h4 className="font-medium text-blue-800">Security Notice</h4>
            <p className="text-sm text-blue-600">
              Keep your API keys secure and never share them in publicly accessible areas such as GitHub, client-side code, or public forums.
            </p>
          </div>
        </div>
      </div>
      
      <div>
        <h3 className="text-xl font-medium mb-4">API Documentation</h3>
        <div className="prose max-w-none">
          <p className="text-gray-600 mb-4">
            Use your API key to send SMS messages programmatically through our API.
          </p>

          <div className="bg-gray-50 p-6 rounded-lg border border-gray-200 mb-6">
            <h4 className="text-lg font-medium mb-4">Send SMS Message</h4>
            <p className="text-sm text-gray-600 mb-4">
              Send an SMS message using your API key and one of your virtual phone numbers.
            </p>

            <div className="space-y-4">
              <div>
                <h5 className="font-medium mb-2">Endpoint</h5>
                <div className="space-y-2">
                  <div>
                    <p className="text-sm text-gray-600 mb-1">API:</p>
                    <code className="bg-gray-100 px-2 py-1 rounded block">
                      POST https://api.yourdomain.com/v1/sms
                    </code>
                  </div>
                  
                </div>
              </div>

              <div>
                <h5 className="font-medium mb-2">Headers</h5>
                <pre className="bg-gray-100 p-3 rounded overflow-x-auto">
{`{
  "Content-Type": "application/json",
  "x-api-key": "your_api_key_here"
}`}
                </pre>
              </div>

              <div>
                <h5 className="font-medium mb-2">Request Body</h5>
                <pre className="bg-gray-100 p-3 rounded overflow-x-auto">
{`{
  "to": "+447700900000",      // Recipient's phone number (required)
  "message": "Hello!",        // Message content (required)
  "from": "+447700900001"     // Your virtual number to send from (required)
}`}
                </pre>
              </div>

              <div>
                <h5 className="font-medium mb-2">Example Response</h5>
                <pre className="bg-gray-100 p-3 rounded overflow-x-auto">
{`{
  "success": true,
  "message_id": "12345",
  "from": "+447700900001",
  "to": "+447700900000",
  "cost": 0.10,
  "remaining_balance": 9.90
}`}
                </pre>
              </div>

              <div>
                <h5 className="font-medium mb-2">Example Usage (Node.js)</h5>
                <pre className="bg-gray-100 p-3 rounded overflow-x-auto">
{`const response = await fetch('${import.meta.env.VITE_SUPABASE_URL}/functions/v1/api-send-sms', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': 'your_api_key_here'
  },
  body: JSON.stringify({
    to: '+447700900000',
    message: 'Hello from API!',
    from: '+447700900001'  // Required: specify which of your numbers to use
  })
});

const result = await response.json();
console.log(result);`}
                </pre>
              </div>

              <div>
                <h5 className="font-medium mb-2">Example Usage (Python)</h5>
                <pre className="bg-gray-100 p-3 rounded overflow-x-auto">
{`import requests

response = requests.post(
    '${import.meta.env.VITE_SUPABASE_URL}/functions/v1/api-send-sms',
    headers={
        'Content-Type': 'application/json',
        'x-api-key': 'your_api_key_here'
    },
    json={
        'to': '+447700900000',
        'message': 'Hello from API!',
        'from': '+447700900001'  # Required: specify which of your numbers to use
    }
)

result = response.json()
print(result)`}
                </pre>
              </div>
            </div>
          </div>

          <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
            <div className="flex space-x-3">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-blue-500" />
              </div>
              <div>
                <h4 className="font-medium text-blue-800">Rate Limits</h4>
                <p className="text-sm text-blue-600">
                  API requests are limited to 10 requests per second per API key. If you need higher limits, please contact support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKeysManager;
