import { useState } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

export const useBalance = () => {
  const [loading, setLoading] = useState(false);

  const addCredits = async (quantity: number) => {
    try {
      setLoading(true);
      
      // Create a checkout session with the selected quantity
      const { data, error } = await supabase.functions.invoke('create-checkout-session', {
        body: { 
          quantity,
          success_url: `${window.location.origin}/dashboard?success=true&credits=${quantity}&session_id={CHECKOUT_SESSION_ID}`
        }
      });

      if (error) throw error;
      if (!data?.url) throw new Error('No checkout URL received');

      // Redirect to Stripe Checkout
      window.location.href = data.url;

    } catch (error) {
      console.error('Error initiating payment:', error);
      toast.error('Failed to initiate payment');
      setLoading(false);
    }
  };

  const getBalance = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('balance')
        .eq('id', userId)
        .single();

      if (error) throw error;
      return data?.balance || 0;
    } catch (error) {
      console.error('Error fetching balance:', error);
      toast.error('Failed to fetch balance');
      return 0;
    }
  };

  return {
    addCredits,
    getBalance,
    loading
  };
}; 