
import React from 'react';
import Navbar from '@/components/Navbar';
import { FileText } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
          <div className="flex items-center justify-center mb-8">
            <div className="p-2 bg-blue-100 rounded-full">
              <FileText className="h-8 w-8 text-blue-600" />
            </div>
          </div>
          
          <h1 className="text-4xl font-bold text-center mb-8">Terms of Service</h1>
          <div className="bg-white p-8 rounded-lg shadow-sm">
            <div className="prose prose-blue max-w-none">
              <p className="text-gray-600">Last updated: July 1, 2023</p>
              
              <h2>1. Agreement to Terms</h2>
              <p>
                These Terms of Service ("Terms") constitute a legally binding agreement between you and EasySMS governing your access to and use of the website, applications, and services (collectively, the "Services").
              </p>
              <p>
                By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Services.
              </p>
              
              <h2>2. Description of Services</h2>
              <p>
                EasySMS provides virtual phone numbers and messaging services that allow users to send and receive text messages. Our Services include:
              </p>
              <ul>
                <li>Purchasing and managing virtual phone numbers</li>
                <li>Sending SMS messages</li>
                <li>API access for integration with your applications</li>
              </ul>
              
              <h2>3. Account Registration</h2>
              <p>
                To use certain features of the Services, you must register for an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
              </p>
              <p>
                You are responsible for safeguarding your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
              </p>
              
              <h2>4. Fees and Payment</h2>
              <p>
                EasySMS charges fees for certain aspects of the Services, including monthly fees for phone numbers and per-message charges for SMS messages.
              </p>
              <p>
                You agree to pay all applicable fees and charges incurred in connection with your account. All fees are non-refundable except as expressly set forth in these Terms or as required by applicable law.
              </p>
              
              <h2>5. Acceptable Use</h2>
              <p>
                You agree not to use the Services to:
              </p>
              <ul>
                <li>Violate any applicable law or regulation</li>
                <li>Infringe the rights of others</li>
                <li>Send unsolicited commercial messages (spam)</li>
                <li>Transmit any harmful code or attacks</li>
                <li>Interfere with the operation of the Services</li>
                <li>Engage in fraudulent activities</li>
              </ul>
              
              <h2>6. API Usage</h2>
              <p>
                If you use our API, you agree to comply with our API documentation and any usage limitations. We reserve the right to modify, throttle, or revoke API access at any time.
              </p>
              
              <h2>7. Intellectual Property</h2>
              <p>
                The Services and all content, features, and functionality thereof are owned by EasySMS and are protected by copyright, trademark, and other intellectual property laws.
              </p>
              <p>
                You may not copy, modify, distribute, sell, or lease any part of the Services without our prior written consent.
              </p>
              
              <h2>8. Privacy</h2>
              <p>
                Our Privacy Policy governs our collection and use of your personal information. By using the Services, you consent to the collection and use of information as set forth in the Privacy Policy.
              </p>
              
              <h2>9. Termination</h2>
              <p>
                We may terminate or suspend your account and access to the Services at any time, without prior notice or liability, for any reason, including if you breach these Terms.
              </p>
              <p>
                You may terminate your account at any time by contacting us.
              </p>
              
              <h2>10. Disclaimer of Warranties</h2>
              <p>
                THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
              </p>
              
              <h2>11. Limitation of Liability</h2>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, EasySMS SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY.
              </p>
              
              <h2>12. Changes to Terms</h2>
              <p>
                We may modify these Terms at any time. If we make material changes, we will provide notice through the Services or by other means. Your continued use of the Services after the changes are made constitutes your acceptance of the changes.
              </p>
              
              <h2>13. Governing Law</h2>
              <p>
                These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions.
              </p>
              
              <h2>14. Contact Information</h2>
              <p>
                If you have any questions about these Terms, please contact us at:
              </p>
              <p>
                Email: legal@EasySMS.com<br />
                Address: 123 Tech Street, London, UK
              </p>
            </div>
          </div>
          
          <div className="mt-8 text-center">
            <Link to="/">
              <Button variant="outline">Return to Homepage</Button>
            </Link>
          </div>
        </div>
      </main>
      
      <footer className="py-8 bg-gray-900 text-white mt-16">
        <div className="container px-4 mx-auto">
          <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
            <div className="text-gray-400 text-sm mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} EasySMS. All rights reserved.
            </div>
            <div className="flex space-x-6">
              <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                Privacy
              </Link>
              <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                Terms
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Terms;
