import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { Code, Copy, CheckCircle, Server, MessageSquare, Phone } from 'lucide-react';
import Navbar from '@/components/Navbar';
import { CopyButton } from '@/components/CopyButton';

const ApiDocs = () => {
  const [activeTab, setActiveTab] = useState('introduction');

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      <main className="container max-w-6xl mx-auto px-4 py-12">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar */}
          <div className="lg:w-64 flex-shrink-0">
            <div className="sticky top-24 p-6 bg-white rounded-lg shadow-sm border border-gray-100">
              <h2 className="text-xl font-semibold mb-4">API Documentation</h2>
              <nav className="space-y-1">
                <button 
                  onClick={() => setActiveTab('introduction')} 
                  className={`w-full text-left px-3 py-2 rounded-md text-sm font-medium transition-colors ${activeTab === 'introduction' ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  Introduction
                </button>
                <button 
                  onClick={() => setActiveTab('authentication')} 
                  className={`w-full text-left px-3 py-2 rounded-md text-sm font-medium transition-colors ${activeTab === 'authentication' ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  Authentication
                </button>
                <button 
                  onClick={() => setActiveTab('phone-numbers')} 
                  className={`w-full text-left px-3 py-2 rounded-md text-sm font-medium transition-colors ${activeTab === 'phone-numbers' ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  Phone Numbers
                </button>
                <button 
                  onClick={() => setActiveTab('messaging')} 
                  className={`w-full text-left px-3 py-2 rounded-md text-sm font-medium transition-colors ${activeTab === 'messaging' ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  Messaging
                </button>
                <button 
                  onClick={() => setActiveTab('errors')} 
                  className={`w-full text-left px-3 py-2 rounded-md text-sm font-medium transition-colors ${activeTab === 'errors' ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  Errors
                </button>
              </nav>
              <div className="mt-8 pt-6 border-t border-gray-100">
                <Link to="/dashboard">
                  <Button variant="outline" className="w-full">Back to Dashboard</Button>
                </Link>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="flex-1">
            <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-8">
              {activeTab === 'introduction' && (
                <div>
                  <h1 className="text-3xl font-semibold mb-6">EasySMS API</h1>
                  <p className="text-gray-600 mb-6">
                    The EasySMS API allows you to integrate our virtual phone number and 
                    messaging services directly into your applications.
                  </p>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">Base URL</h2>
                    <div className="bg-gray-50 p-4 rounded-md flex items-center">
                      <code className="text-sm font-mono text-gray-800">https://api.EasySMS.com/v1</code>
                      <CopyButton textToCopy="https://api.EasySMS.com/v1" />
                    </div>
                  </div>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">Request Format</h2>
                    <p className="text-gray-600 mb-4">
                      The API accepts JSON-encoded request bodies and returns JSON-encoded responses.
                    </p>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm mb-4">
                      <div className="text-gray-500">// Request</div>
                      <pre className="text-gray-800">
{`POST /messages HTTP/1.1
Host: api.EasySMS.com
Authorization: Bearer YOUR_API_KEY
Content-Type: application/json

{
  "from": "+447700123456",
  "to": "+447700654321",
  "message": "Hello from EasySMS!"
}`}
                      </pre>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <div className="text-gray-500">// Response</div>
                      <pre className="text-gray-800">
{`{
  "id": "msg_123abc456def",
  "status": "queued",
  "from": "+447700123456",
  "to": "+447700654321",
  "created_at": "2023-05-10T14:30:00Z"
}`}
                      </pre>
                    </div>
                  </div>
                  
                  <div>
                    <h2 className="text-xl font-medium mb-4">Rate Limits</h2>
                    <p className="text-gray-600 mb-4">
                      The API is rate limited to 100 requests per minute per API key. If you exceed 
                      this limit, you will receive a 429 Too Many Requests response.
                    </p>
                    <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded-md">
                      <div className="flex">
                        <CheckCircle className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                        <p className="text-blue-700">
                          Need higher rate limits? Contact our support team to discuss enterprise options.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'authentication' && (
                <div>
                  <h1 className="text-3xl font-semibold mb-6">Authentication</h1>
                  <p className="text-gray-600 mb-6">
                    The EasySMS API uses API keys to authenticate requests. You can view and manage 
                    your API keys in your dashboard.
                  </p>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">API Keys</h2>
                    <p className="text-gray-600 mb-4">
                      All API requests must include your API key in the Authorization header:
                    </p>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <code className="text-gray-800">
                        Authorization: Bearer YOUR_API_KEY
                      </code>
                    </div>
                  </div>
                  
                  <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 rounded-md mb-8">
                    <p className="text-yellow-700">
                      <strong>Important:</strong> Keep your API keys secure and never share them publicly. 
                      Each API key's usage is tracked and logged for security purposes.
                      If you believe your API key has been compromised, you should revoke it immediately 
                      and generate a new one from your dashboard.
                    </p>
                  </div>
                  
                  <div>
                    <h2 className="text-xl font-medium mb-4">Managing API Keys</h2>
                    <p className="text-gray-600 mb-4">
                      You can manage your API keys in the dashboard:
                    </p>
                    <ol className="list-decimal ml-5 space-y-2 text-gray-600">
                      <li>Go to your Dashboard</li>
                      <li>Navigate to the "API Keys" section</li>
                      <li>Here you can:
                        <ul className="list-disc ml-5 mt-2">
                          <li>Generate new API keys with custom names</li>
                          <li>View when each key was last used</li>
                          <li>Revoke (deactivate) keys that are no longer needed</li>
                          <li>Delete keys that you won't use again</li>
                        </ul>
                      </li>
                    </ol>
                    <div className="mt-6">
                      <Link to="/dashboard">
                        <Button>Go to API Keys Management</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'phone-numbers' && (
                <div>
                  <h1 className="text-3xl font-semibold mb-6">Phone Numbers API</h1>
                  <p className="text-gray-600 mb-6">
                    The Phone Numbers API allows you to list, query, and manage your virtual phone numbers.
                  </p>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">List Your Numbers</h2>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm mb-4">
                      <div className="text-gray-500">// Request</div>
                      <pre className="text-gray-800">
{`GET /numbers HTTP/1.1
Host: api.EasySMS.com
Authorization: Bearer YOUR_API_KEY`}
                      </pre>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <div className="text-gray-500">// Response</div>
                      <pre className="text-gray-800">
{`{
  "numbers": [
    {
      "id": "num_abc123",
      "number": "+447700123456",
      "country": "GB",
      "active": true,
      "monthly_fee": "2.00",
      "currency": "GBP",
      "acquired_at": "2023-05-10T14:30:00Z"
    },
    {
      "id": "num_def456",
      "number": "+447700654321",
      "country": "GB",
      "active": true,
      "monthly_fee": "2.00",
      "currency": "GBP",
      "acquired_at": "2023-07-22T10:15:00Z"
    }
  ],
  "meta": {
    "total": 2,
    "limit": 10,
    "offset": 0
  }
}`}
                      </pre>
                    </div>
                  </div>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">Get Number Details</h2>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm mb-4">
                      <div className="text-gray-500">// Request</div>
                      <pre className="text-gray-800">
{`GET /numbers/{number_id} HTTP/1.1
Host: api.EasySMS.com
Authorization: Bearer YOUR_API_KEY`}
                      </pre>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <div className="text-gray-500">// Response</div>
                      <pre className="text-gray-800">
{`{
  "id": "num_abc123",
  "number": "+447700123456",
  "country": "GB",
  "active": true,
  "monthly_fee": "2.00",
  "currency": "GBP",
  "acquired_at": "2023-05-10T14:30:00Z",
  "messages_sent": 43,
  "next_billing_date": "2023-06-10T00:00:00Z"
}`}
                      </pre>
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'messaging' && (
                <div>
                  <h1 className="text-3xl font-semibold mb-6">Messaging API</h1>
                  <p className="text-gray-600 mb-6">
                    The Messaging API allows you to send SMS messages from your virtual phone numbers.
                  </p>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">Send a Message</h2>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm mb-4">
                      <div className="text-gray-500">// Request</div>
                      <pre className="text-gray-800">
{`POST /messages HTTP/1.1
Host: api.EasySMS.com
Authorization: Bearer YOUR_API_KEY
Content-Type: application/json

{
  "from": "+447700123456",  // Required: One of your configured phone numbers
  "to": "+447700654321",    // Required: Recipient's phone number
  "message": "Hello from EasySMS!"  // Required: Message content
}`}
                      </pre>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <div className="text-gray-500">// Response</div>
                      <pre className="text-gray-800">
{`{
  "id": "msg_123abc456def",
  "status": "queued",
  "from": "+447700123456",
  "to": "+447700654321",
  "created_at": "2023-05-10T14:30:00Z"
}`}
                      </pre>
                    </div>
                  </div>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">Get Message Status</h2>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm mb-4">
                      <div className="text-gray-500">// Request</div>
                      <pre className="text-gray-800">
{`GET /messages/{message_id} HTTP/1.1
Host: api.EasySMS.com
Authorization: Bearer YOUR_API_KEY`}
                      </pre>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <div className="text-gray-500">// Response</div>
                      <pre className="text-gray-800">
{`{
  "id": "msg_123abc456def",
  "status": "delivered",
  "from": "+447700123456",
  "to": "+447700654321",
  "created_at": "2023-05-10T14:30:00Z",
  "updated_at": "2023-05-10T14:30:05Z",
  "cost": "0.10",
  "currency": "GBP"
}`}
                      </pre>
                    </div>
                  </div>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">List Messages</h2>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm mb-4">
                      <div className="text-gray-500">// Request</div>
                      <pre className="text-gray-800">
{`GET /messages?limit=10&offset=0 HTTP/1.1
Host: api.EasySMS.com
Authorization: Bearer YOUR_API_KEY`}
                      </pre>
                    </div>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <div className="text-gray-500">// Response</div>
                      <pre className="text-gray-800">
{`{
  "messages": [
    {
      "id": "msg_123abc456def",
      "status": "delivered",
      "from": "+447700123456",
      "to": "+447700654321",
      "created_at": "2023-05-10T14:30:00Z"
    },
    {
      "id": "msg_789ghi012jkl",
      "status": "delivered",
      "from": "+447700123456",
      "to": "+447700987654",
      "created_at": "2023-05-10T14:35:00Z"
    }
  ],
  "meta": {
    "total": 43,
    "limit": 10,
    "offset": 0
  }
}`}
                      </pre>
                    </div>
                  </div>
                </div>
              )}
              
              {activeTab === 'errors' && (
                <div>
                  <h1 className="text-3xl font-semibold mb-6">Error Handling</h1>
                  <p className="text-gray-600 mb-6">
                    This page provides information about the errors you might encounter when using the EasySMS API.
                  </p>
                  
                  <div className="mb-8">
                    <h2 className="text-xl font-medium mb-4">Error Format</h2>
                    <p className="text-gray-600 mb-4">
                      When an error occurs, the API returns a JSON response with an error object:
                    </p>
                    <div className="bg-gray-50 p-4 rounded-md font-mono text-sm">
                      <pre className="text-gray-800">
{`{
  "error": {
    "code": "invalid_request",
    "message": "The 'to' parameter is required",
    "status": 400,
    "request_id": "req_abc123def456"
  }
}`}
                      </pre>
                    </div>
                  </div>
                  
                  <div>
                    <h2 className="text-xl font-medium mb-4">Common Error Codes</h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Status Code
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Error Code
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">400</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">invalid_request</td>
                            <td className="px-6 py-4 text-sm text-gray-500">The request was unacceptable, often due to missing a required parameter (from, to, or message).</td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">401</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">unauthorized</td>
                            <td className="px-6 py-4 text-sm text-gray-500">No valid API key provided or the key has been deactivated.</td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">403</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">forbidden</td>
                            <td className="px-6 py-4 text-sm text-gray-500">The API key is valid but doesn't have permission to use the specified phone number.</td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">404</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">not_found</td>
                            <td className="px-6 py-4 text-sm text-gray-500">The specified phone number doesn't exist or isn't configured.</td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">429</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">rate_limit_exceeded</td>
                            <td className="px-6 py-4 text-sm text-gray-500">Too many requests hit the API too quickly.</td>
                          </tr>
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">500</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">server_error</td>
                            <td className="px-6 py-4 text-sm text-gray-500">Something went wrong on our end.</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ApiDocs;
