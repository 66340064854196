import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import { Send, AlertCircle } from 'lucide-react';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from '@/hooks/useAuth';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface MessageFormProps {
  phoneNumbers: Array<{
    id: string;
    number: string;
  }>;
  selectedPhoneNumberId?: string;
}

// SMS message constants
const SMS_CHARACTER_LIMIT = 160;
const PRICE_PER_MESSAGE = 0.10; // £0.10 per message

const MessageForm = ({ phoneNumbers, selectedPhoneNumberId }: MessageFormProps) => {
  const { user, profile } = useAuth();
  const [selectedNumberId, setSelectedNumberId] = useState(selectedPhoneNumberId || '');
  const [recipient, setRecipient] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [messageCount, setMessageCount] = useState(1);
  const [insufficientFunds, setInsufficientFunds] = useState(false);

  // Update selected number when prop changes
  useEffect(() => {
    if (selectedPhoneNumberId) {
      setSelectedNumberId(selectedPhoneNumberId);
    }
  }, [selectedPhoneNumberId]);

  // Calculate message segments based on character count
  useEffect(() => {
    if (message.length <= 0) {
      setMessageCount(1);
      return;
    }
    setMessageCount(Math.ceil(message.length / SMS_CHARACTER_LIMIT));
  }, [message]);
  
  // Check if user has enough funds
  useEffect(() => {
    if (profile && profile.balance) {
      const messageCost = PRICE_PER_MESSAGE * messageCount;
      setInsufficientFunds(profile.balance < messageCost);
    }
  }, [profile, messageCount]);

  const sendSMSViaAPI = async (phoneNumber: string, recipientNumber: string, messageText: string) => {
    const { data: { session } } = await supabase.auth.getSession();
    
    if (!session?.access_token) {
      throw new Error('No active session');
    }

    // First get credentials for this number from the phone_number_credentials table
    const { data: credentials, error: credentialsError } = await supabase
      .from('phone_number_credentials')
      .select('api_username, api_password')
      .eq('number', phoneNumber.replace(/\s/g, ''))
      .single();
      
    if (credentialsError || !credentials) {
      throw new Error('Failed to retrieve phone number credentials');
    }
    
    if (!credentials.api_username || !credentials.api_password) {
      throw new Error('This phone number is still being configured');
    }

    const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/send-sms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.access_token}`
      },
      body: JSON.stringify({
        username: credentials.api_username,
        password: credentials.api_password,
        recipient: recipientNumber,
        message: messageText
      })
    });

    const result = await response.json();
    
    if (!result.success) {
      throw new Error(result.error || 'Failed to send SMS');
    }

    return result.response;
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) {
      toast.error('You must be logged in to send messages');
      return;
    }
    
    if (!selectedNumberId) {
      toast.error('Please select a phone number to send from');
      return;
    }
    
    if (!recipient) {
      toast.error('Please enter a recipient phone number');
      return;
    }
    
    if (!message) {
      toast.error('Please enter a message to send');
      return;
    }
    
    if (!isValidUKPhoneNumber(recipient)) {
      toast.error('Please enter a valid UK phone number');
      return;
    }
    
    const messageCost = PRICE_PER_MESSAGE * messageCount;
    
    if (profile && profile.balance < messageCost) {
      toast.error(`Insufficient funds. Your balance: £${profile.balance.toFixed(2)}, Cost: £${messageCost.toFixed(2)}`);
      return;
    }
    
    setIsSending(true);
    
    try {
      // 1. Get the phone number details
      const { data: phoneDetails, error: phoneError } = await supabase
        .from('phone_numbers')
        .select('number')
        .eq('id', selectedNumberId)
        .single();
        
      if (phoneError) throw phoneError;

      // 2. Send the SMS via A&A's API
      await sendSMSViaAPI(phoneDetails.number, recipient, message);

      // 3. Insert message record
      const { data: messageData, error: messageError } = await supabase
        .from('messages')
        .insert({
          user_id: user.id,
          phone_number_id: selectedNumberId,
          recipient: recipient,
          content: message,
          cost: messageCost,
          status: 'sent'
        })
        .select()
        .single();
        
      if (messageError) throw messageError;
      
      // 4. Update user balance
      const { error: balanceError } = await supabase
        .from('profiles')
        .update({
          balance: (profile?.balance || 0) - messageCost
        })
        .eq('id', user.id);
        
      if (balanceError) throw balanceError;
      
      // 5. Update phone number messages_sent count
      const { error: phoneUpdateError } = await supabase
        .from('phone_numbers')
        .update({
          messages_sent: (await supabase
            .from('phone_numbers')
            .select('messages_sent')
            .eq('id', selectedNumberId)
            .single()
          ).data?.messages_sent + 1 || 1
        })
        .eq('id', selectedNumberId)
        .eq('user_id', user.id);
        
      if (phoneUpdateError) throw phoneUpdateError;
      
      toast.success('Message sent successfully');
      
      // Reset form
      setRecipient('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      if (error instanceof Error) {
        toast.error(error.message || 'Failed to send message');
      } else {
        toast.error('Failed to send message');
      }
    } finally {
      setIsSending(false);
    }
  };

  const isValidUKPhoneNumber = (phone: string) => {
    // UK phone number validation - can be improved
    const ukPhonePattern = /^(\+44|0)[1-9]\d{8,9}$/;
    return ukPhonePattern.test(phone.replace(/\s/g, ''));
  };

  const calculateMessageCost = () => {
    // Price per message segment
    return `£${(PRICE_PER_MESSAGE * messageCount).toFixed(2)}`;
  };

  const getCharacterCountColor = () => {
    if (message.length > SMS_CHARACTER_LIMIT) {
      return 'text-yellow-600';
    }
    if (message.length > SMS_CHARACTER_LIMIT * 0.8) {
      return 'text-yellow-500';
    }
    return 'text-gray-500';
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Send Message</CardTitle>
        <CardDescription>
          Send SMS messages using your virtual phone numbers
        </CardDescription>
      </CardHeader>
      
      {insufficientFunds && (
        <div className="px-6">
          <Alert className="mb-4 bg-yellow-50 text-yellow-800 border-yellow-200">
            <AlertCircle className="h-4 w-4 text-yellow-800" />
            <AlertDescription>
              Insufficient funds for this message. Your balance: £{profile?.balance?.toFixed(2) || '0.00'}, Message cost: {calculateMessageCost()}
            </AlertDescription>
          </Alert>
        </div>
      )}
      
      <form onSubmit={handleSendMessage}>
        <CardContent className="space-y-4">
          <div className="space-y-1">
            <label htmlFor="fromNumber" className="text-sm font-medium">
              From
            </label>
            <Select value={selectedNumberId} onValueChange={setSelectedNumberId}>
              <SelectTrigger id="fromNumber">
                <SelectValue placeholder="Select a phone number" />
              </SelectTrigger>
              <SelectContent>
                {phoneNumbers.map((number) => (
                  <SelectItem key={number.id} value={number.id}>
                    {number.number}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-1">
            <label htmlFor="recipient" className="text-sm font-medium">
              To (UK numbers only)
            </label>
            <Input
              id="recipient"
              placeholder="+44 7700 900000"
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
            />
          </div>

          <div className="space-y-1">
            <label htmlFor="message" className="text-sm font-medium">
              Message
            </label>
            <Textarea
              id="message"
              placeholder="Type your message here..."
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="resize-none"
              maxLength={SMS_CHARACTER_LIMIT * 3} // Allow up to 3 message segments
            />
            <div className="flex justify-between text-xs mt-1">
              <span className={getCharacterCountColor()}>
                {message.length} / {SMS_CHARACTER_LIMIT} characters
                {messageCount > 1 && ` (${messageCount} segments)`}
              </span>
              <span>Cost: {calculateMessageCost()}</span>
            </div>
          </div>
        </CardContent>
        <CardFooter>
          <Button 
            type="submit" 
            className="w-full" 
            disabled={isSending || insufficientFunds || !selectedNumberId || !recipient || !message}
          >
            {isSending ? (
              <>Sending...</>
            ) : (
              <>
                <Send className="h-4 w-4 mr-2" />
                Send Message
              </>
            )}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};

export default MessageForm;
