
import React from 'react';
import { TabsContent } from '@/components/ui/tabs';
import ApiKeysManager from '@/components/ApiKeysManager';

const ApiKeysTab = () => {
  return (
    <TabsContent value="api-keys">
      <ApiKeysManager />
    </TabsContent>
  );
};

export default ApiKeysTab;
