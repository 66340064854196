import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { PhoneOutgoing, MessageCircle, Trash2, AlertCircle } from 'lucide-react';
import { PhoneNumberTier } from '@/types/phoneNumbers';
import { supabase } from '@/integrations/supabase/client';

interface PhoneNumberCardProps {
  number: string;
  location: string;
  status: 'active' | 'pending';
  messagesSent: number;
  monthlyFee: string;
  purchaseDate: string;
  onSendMessage: () => void;
  onDelete: () => void;
  tier: PhoneNumberTier;
  tierBadgeClass: string;
  isConfigured?: boolean;
}

const PhoneNumberCard: React.FC<PhoneNumberCardProps> = ({
  number,
  location,
  status,
  messagesSent,
  monthlyFee,
  purchaseDate,
  onSendMessage,
  onDelete,
  tier,
  tierBadgeClass,
  isConfigured = false
}) => {
  const [hasCredentials, setHasCredentials] = useState(isConfigured);
  
  // Check if the number has credentials in the credentials table
  useEffect(() => {
    const checkCredentials = async () => {
      try {
        const { count, error } = await supabase
          .from('phone_number_credentials')
          .select('*', { count: 'exact', head: true })
          .eq('number', number.replace(/\s/g, ''))
          .not('api_username', 'is', null)
          .not('api_password', 'is', null);
          
        if (error) {
          console.error('Error checking credentials:', error);
          setHasCredentials(false);
          return;
        }
        
        setHasCredentials(count === 1);
      } catch (error) {
        console.error('Error in credentials check:', error);
        setHasCredentials(false);
      }
    };
    
    if (status === 'active') {
      checkCredentials();
    }
  }, [number, status]);

  return (
    <Card className="overflow-hidden transition-all duration-300 hover:shadow-md">
      <CardHeader className="pb-4">
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="text-xl font-medium">{number}</CardTitle>
            <CardDescription>{location}</CardDescription>
          </div>
          <div className="flex gap-2">
            
            {tier && (
              <div className={`px-2 py-1 rounded-full text-xs font-medium ${tierBadgeClass}`}>
                {tier.charAt(0).toUpperCase() + tier.slice(1)}
              </div>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-2 mb-4">
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Status</span>
            <span className={`font-medium ${status === 'active' && hasCredentials ? 'text-green-600' : 'text-yellow-600'}`}>
              {status === 'active' && hasCredentials ? 'Active' : 'Pending'}
            </span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Messages Sent</span>
            <span>{messagesSent}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Monthly Fee</span>
            <span>{monthlyFee}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-500">Purchase Date</span>
            <span>{purchaseDate}</span>
          </div>
        </div>

        {!hasCredentials && status === 'active' && (
          <div className="mb-4 p-3 bg-yellow-50 border border-yellow-100 rounded-lg">
            <div className="flex items-start space-x-2">
              <AlertCircle className="h-5 w-5 text-yellow-500 mt-0.5" />
              <div>
                <p className="text-sm text-yellow-800">
                  This number is being configured. You'll be able to send messages soon.
                </p>
              </div>
            </div>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex gap-2 justify-between border-t pt-4">
        <Button
          className="flex-1"
          onClick={onSendMessage}
          disabled={!hasCredentials || status !== 'active'}
        >
          <MessageCircle className="h-4 w-4 mr-2" />
          Send Message
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={onDelete}
          className="hover:bg-red-50 hover:text-red-600 hover:border-red-200"
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </CardFooter>
    </Card>
  );
};

interface PhoneNumberPurchaseCardProps {
  number: string;
  location: string;
  availability: string;
  price: string;
  onPurchase?: () => void;
  tier?: PhoneNumberTier;
  tierBadgeClass?: string;
}

const PhoneNumberPurchaseCard = ({
  number,
  location,
  availability,
  price,
  onPurchase,
  tier = 'standard',
  tierBadgeClass = 'bg-blue-100 text-blue-800'
}: PhoneNumberPurchaseCardProps) => {
  return (
    <Card className="overflow-hidden transition-all duration-300 hover:shadow-md">
      <CardHeader className="pb-4">
        <div className="flex justify-between items-start">
          <div>
            <CardTitle className="text-xl font-medium">{number}</CardTitle>
            <CardDescription>{location}</CardDescription>
          </div>
          <div className="flex gap-2">
            <div className="px-2 py-1 rounded-full bg-blue-100 text-blue-800 text-xs font-medium">
              Available
            </div>
            {tier && tier !== 'standard' && (
              <div className={`px-2 py-1 rounded-full text-xs font-medium ${tierBadgeClass}`}>
                {tier.charAt(0).toUpperCase() + tier.slice(1)}
              </div>
            )}
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2 text-sm">
          <div className="flex flex-col">
            <span className="text-gray-500">Availability</span>
            <span className="font-medium">{availability}</span>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-500">Price</span>
            <span className="font-medium">{price}</span>
          </div>
        </div>
      </CardContent>
      <CardFooter className="border-t pt-4">
        <Button className="w-full" onClick={onPurchase}>
          <PhoneOutgoing className="h-4 w-4 mr-2" />
          Purchase Number
        </Button>
      </CardFooter>
    </Card>
  );
};

export { PhoneNumberCard, PhoneNumberPurchaseCard };
