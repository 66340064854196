import React, { useState } from 'react';
import { TabsContent } from '@/components/ui/tabs';
import { PhoneNumberCard } from '@/components/PhoneNumberCard';
import { Loader2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { PhoneNumberTier } from '@/types/phoneNumbers';
import { formatUKPhoneNumber } from '@/utils/formatPhoneNumber';

interface PhoneNumber {
  id: string;
  number: string;
  location: string;
  status: 'active' | 'pending';
  tier: PhoneNumberTier;
  monthly_fee: number;
  purchase_date: string;
  messages_sent: number;
  api_username?: string;
  api_password?: string;
}

interface MyNumbersTabProps {
  myNumbers: PhoneNumber[];
  isLoading: boolean;
  onSendMessage: (numberId: string) => void;
  onDeleteNumber: (numberId: string) => void;
  setActiveTab: (tab: string) => void;
  getTierBadgeClass: (tier: PhoneNumberTier) => string;
}

const MyNumbersTab: React.FC<MyNumbersTabProps> = ({
  myNumbers,
  isLoading,
  onSendMessage,
  onDeleteNumber,
  setActiveTab,
  getTierBadgeClass
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [numberToDelete, setNumberToDelete] = useState<PhoneNumber | null>(null);

  const handleDeleteClick = (number: PhoneNumber) => {
    setNumberToDelete(number);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (numberToDelete) {
      onDeleteNumber(numberToDelete.id);
      setIsDeleteDialogOpen(false);
      setNumberToDelete(null);
    }
  };

  if (isLoading) {
    return (
      <TabsContent value="my-numbers" className="py-12 text-center">
        <Loader2 className="h-8 w-8 mx-auto animate-spin text-blue-500" />
        <p className="mt-4 text-gray-500">Loading your phone numbers...</p>
      </TabsContent>
    );
  }
  
  return (
    <TabsContent value="my-numbers" className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {myNumbers.map((number) => (
          <PhoneNumberCard
            key={number.id}
            number={formatUKPhoneNumber(number.number)}
            location={number.location}
            status={number.status}
            messagesSent={number.messages_sent}
            monthlyFee={`£${number.monthly_fee.toFixed(2)}`}
            purchaseDate={new Date(number.purchase_date).toLocaleDateString()}
            onSendMessage={() => onSendMessage(number.id)}
            onDelete={() => handleDeleteClick(number)}
            tier={number.tier}
            tierBadgeClass={getTierBadgeClass(number.tier)}
            isConfigured={!!(number.api_username && number.api_password)}
          />
        ))}
      </div>
      
      {myNumbers.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">You don't have any phone numbers yet</p>
          <Button onClick={() => setActiveTab('buy-numbers')}>
            Buy Your First Number
          </Button>
        </div>
      )}

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Number Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete the number {numberToDelete?.number}? This action cannot be undone, and you will lose this number immediately.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleConfirmDelete}>
              Delete Number
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </TabsContent>
  );
};

export default MyNumbersTab;
