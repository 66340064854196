import React, { useState, useEffect } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import AccountInfo from '@/components/AccountInfo';
import DashboardHeader from '@/components/dashboard/DashboardHeader';
import PhoneNumbersTabs from '@/components/dashboard/PhoneNumbersTabs';
import MessageFormTab from '@/components/dashboard/MessageFormTab';
import ApiKeysTab from '@/components/dashboard/ApiKeysTab';
import SentMessagesTab from '@/components/dashboard/tabs/SentMessagesTab';
import { useAuth } from '@/hooks/useAuth';
import { useBalance } from '@/hooks/useBalance';
import { supabase } from '@/integrations/supabase/client';
import { Loader2 } from 'lucide-react';
import { toast } from 'sonner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AvailablePhoneNumber } from '@/types/phoneNumbers';

interface PhoneNumber {
  id: string;
  number: string;
}

interface CreditTransaction {
  id: string;
  user_id: string;
  amount: number;
  type: string;
  payment_id: string;
  status: string;
  description: string;
  created_at: string;
  updated_at: string;
}

const CREDIT_OPTIONS = [
  { value: '50', label: '50 Credits - £5' },
  { value: '100', label: '100 Credits - £10' },
  { value: '250', label: '250 Credits - £25' },
  { value: '500', label: '500 Credits - £50' },
  { value: '1000', label: '1000 Credits - £100' },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState('my-numbers');
  const { user, profile, loading, refreshProfile } = useAuth();
  const { addCredits, loading: balanceLoading } = useBalance();
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
  const [availableNumbers, setAvailableNumbers] = useState<AvailablePhoneNumber[]>([]);
  const [messagesSent, setMessagesSent] = useState(0);
  const [isTopUpDialogOpen, setIsTopUpDialogOpen] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState('50');
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState<string>('');

  const updateCredits = async (sessionId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) {
        throw new Error('No active session');
      }

      // Get the credits amount from the URL
      const credits = searchParams.get('credits');
      if (!credits) {
        throw new Error('Credit amount not found in URL');
      }

      // Check if this is a successful payment
      const success = searchParams.get('success');
      if (success !== 'true') {
        throw new Error('Payment was not successful');
      }

      const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/update-credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          session_id: sessionId,
          user_id: user?.id,
          credits: parseInt(credits)
        })
      });

      const data = await response.json();
      
      if (!data.success) {
        throw new Error(data.error || 'Failed to update credits');
      }

      await refreshProfile();
      toast.success(`Successfully added ${data.credits} credits to your account!`);
    } catch (error) {
      console.error('Error updating credits:', error);
      toast.error('Error updating credits. Please contact support if credits are not added.');
    }
  };

  useEffect(() => {
    // Handle payment status from URL parameters
    const success = searchParams.get('success');
    const sessionId = searchParams.get('session_id');
    const canceled = searchParams.get('canceled');

    if (success === 'true' && sessionId) {
      // Process the payment immediately
      updateCredits(sessionId);
      
      // Remove query parameters
      navigate('/dashboard', { replace: true });
    } else if (canceled === 'true') {
      toast.error('Payment canceled.');
      // Remove query parameters
      navigate('/dashboard', { replace: true });
    }
  }, [searchParams, navigate, user?.id]);

  useEffect(() => {
    // If not loading and no user, redirect to login
    if (!loading && !user) {
      navigate('/login');
      return;
    }

    const fetchDashboardData = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }
      
      try {
        const { data: numbers, error: numbersError } = await supabase
          .from('phone_numbers')
          .select('id, number')
          .eq('user_id', user.id)
          .eq('status', 'active');
          
        if (numbersError) throw numbersError;
        
        const { count, error: messagesError } = await supabase
          .from('messages')
          .select('*', { count: 'exact', head: true })
          .eq('user_id', user.id);
          
        if (messagesError) throw messagesError;

        // Fetch available numbers
        const { data: available, error: availableError } = await supabase
          .from('available_numbers')
          .select('*')
          .eq('is_available', true);

        if (availableError) throw availableError;
        
        setPhoneNumbers(numbers || []);
        setAvailableNumbers(available || []);
        setMessagesSent(count || 0);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        toast.error('Failed to load dashboard data');
      } finally {
        setIsLoading(false);
      }
    };
    
    if (user && !loading) {
      fetchDashboardData();
    }
  }, [user, loading, navigate]);

  const handleTopUp = async () => {
    const quantity = parseInt(selectedQuantity);
    if (isNaN(quantity) || quantity < 1) {
      toast.error('Please select the number of credits');
      return;
    }
    
    setIsTopUpDialogOpen(false);
    await addCredits(quantity);
  };

  const handleDeleteNumber = async (numberId: string) => {
    try {
      // First verify the user owns this number
      const { data: phoneNumber, error: fetchError } = await supabase
        .from('phone_numbers')
        .select('number, user_id')
        .eq('id', numberId)
        .single();

      if (fetchError) throw fetchError;
      
      // Verify ownership
      if (phoneNumber.user_id !== user?.id) {
        throw new Error('You do not have permission to delete this number');
      }

      // Delete the phone number credentials first
      const { error: credentialsError } = await supabase
        .from('phone_number_credentials')
        .delete()
        .eq('number', phoneNumber.number);

      if (credentialsError) {
        console.error('Error deleting credentials:', credentialsError);
        // Continue anyway as the credentials might not exist
      }

      // Delete the phone number
      const { error: deleteError } = await supabase
        .from('phone_numbers')
        .delete()
        .eq('id', numberId)
        .eq('user_id', user?.id); // Extra safety check

      if (deleteError) throw deleteError;

      // Update local state
      setPhoneNumbers(phoneNumbers.filter(n => n.id !== numberId));
      toast.success('Phone number deleted successfully');
    } catch (error) {
      console.error('Error deleting phone number:', error);
      toast.error('Failed to delete phone number');
    }
  };
  
  if (loading || isLoading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <DashboardHeader />
        <div className="flex justify-center items-center h-[80vh]">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          <span className="ml-2 text-lg">Loading your dashboard...</span>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-gray-50">
      <DashboardHeader />
      
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-6xl mx-auto">
          <div className="mb-8">
            <AccountInfo 
              balance={`£${profile?.balance?.toFixed(2) || '0.00'}`}
              activeNumbers={phoneNumbers.length}
              messagesSent={messagesSent}
              nextBillingDate="10th of next month"
              onBuyCredits={() => setIsTopUpDialogOpen(true)}
              isLoadingCredits={balanceLoading}
            />
          </div>
          
          <Tabs value={activeTab} onValueChange={setActiveTab} className="space-y-6">
            <TabsList className="grid grid-cols-5">
              <TabsTrigger value="my-numbers">My Phone Numbers</TabsTrigger>
              <TabsTrigger value="buy-numbers">Buy Numbers</TabsTrigger>
              <TabsTrigger value="messages">Send Messages</TabsTrigger>
              <TabsTrigger value="sent-messages">Sent Messages</TabsTrigger>
              <TabsTrigger value="api-keys">API Keys</TabsTrigger>
            </TabsList>
            
            <PhoneNumbersTabs 
              activeTab={activeTab} 
              setActiveTab={setActiveTab} 
              onSendMessage={(numberId) => {
                setSelectedPhoneNumberId(numberId);
                setActiveTab('messages');
              }}
              onDeleteNumber={(numberId) => handleDeleteNumber(numberId)}
              availableNumbers={availableNumbers}
              setAvailableNumbers={setAvailableNumbers}
            />
            
            <TabsContent value="messages">
              <MessageFormTab 
                phoneNumbers={phoneNumbers} 
                selectedPhoneNumberId={selectedPhoneNumberId}
              />
            </TabsContent>

            <TabsContent value="sent-messages">
              <SentMessagesTab />
            </TabsContent>
            
            <TabsContent value="api-keys">
              <ApiKeysTab />
            </TabsContent>
          </Tabs>
        </div>
      </main>

      <Dialog open={isTopUpDialogOpen} onOpenChange={setIsTopUpDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Buy SMS Credits</DialogTitle>
            <DialogDescription>
              Select the number of SMS credits you want to purchase. Each credit allows you to send one SMS message.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <Select
              value={selectedQuantity}
              onValueChange={setSelectedQuantity}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select credit package" />
              </SelectTrigger>
              <SelectContent>
                {CREDIT_OPTIONS.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button onClick={handleTopUp} className="w-full bg-green-600 hover:bg-green-700 text-white">
              {balanceLoading ? (
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
              ) : null}
              Proceed to Payment
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Dashboard;
