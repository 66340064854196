import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Phone, MessageSquare, Mail } from 'lucide-react';

const Footer = () => {
  const location = useLocation();
  
  const scrollToSection = (sectionId: string) => {
    // Only scroll if we're on the home page
    if (location.pathname !== '/') {
      window.location.href = `/#${sectionId}`;
      return;
    }
    
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="py-12 bg-gray-900 text-white">
      <div className="container px-4 mx-auto">
        {/* Main Footer Content */}
        <div className="flex flex-col md:flex-row justify-between">
          {/* Company Info */}
          <div className="mb-8 md:mb-0 md:w-1/3 md:pr-12">
            <div className="flex items-center mb-4">
              <Phone className="mr-2 text-blue-400" size={24} />
              <span className="text-2xl font-bold">EASY<span className="font-black">SMS</span></span>
            </div>
            <p className="text-gray-400 mb-4">
              UK virtual phone numbers and messaging services for businesses and individuals. A new startup based in London, UK delivering enterprise-grade SMS solutions.
            </p>
          </div>
          
          {/* Links Section */}
          <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-12 md:w-2/3 md:pl-12">
            <div>
              <h4 className="text-lg font-medium mb-4 flex items-center">
                <MessageSquare className="mr-2 text-blue-400" size={16} />
                Product
              </h4>
              <ul className="space-y-3">
                <li><Link to="/api-docs" className="text-gray-400 hover:text-blue-400 transition-colors">Documentation</Link></li>
                <li><Link to="/dashboard" className="text-gray-400 hover:text-blue-400 transition-colors">Dashboard</Link></li>
                <li>
                  <button 
                    onClick={() => scrollToSection('features')} 
                    className="text-gray-400 hover:text-blue-400 transition-colors"
                  >
                    Features
                  </button>
                </li>
                <li>
                  <button 
                    onClick={() => scrollToSection('pricing')} 
                    className="text-gray-400 hover:text-blue-400 transition-colors"
                  >
                    Pricing
                  </button>
                </li>
              </ul>
            </div>
            
            <div>
              <h4 className="text-lg font-medium mb-4 flex items-center">
                <Mail className="mr-2 text-blue-400" size={16} />
                Company
              </h4>
              <ul className="space-y-3">
                <li><Link to="/about" className="text-gray-400 hover:text-blue-400 transition-colors">About Us</Link></li>
                <li><Link to="/contact" className="text-gray-400 hover:text-blue-400 transition-colors">Contact</Link></li>
                <li><Link to="/privacy" className="text-gray-400 hover:text-blue-400 transition-colors">Privacy Policy</Link></li>
                <li><Link to="/terms" className="text-gray-400 hover:text-blue-400 transition-colors">Terms of Service</Link></li>
              </ul>
            </div>
          </div>
        </div>
        
        {/* Bottom Section */}
        <div className="border-t border-gray-800 mt-8 pt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="text-gray-400 text-sm mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} EasySMS Ltd. All rights reserved. Registered in England & Wales. Created by <a href="https://tinycreativeagency.com/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-500 transition-colors">Tiny</a>.
          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;