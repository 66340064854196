import React, { useState, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { PhoneNumberTier, PhoneNumber, AvailablePhoneNumber } from '@/types/phoneNumbers';
import MyNumbersTab from './tabs/MyNumbersTab';
import BuyNumbersTab from './tabs/BuyNumbersTab';
import ConfirmationDialog from '@/components/ui/confirmation-dialog';

interface PhoneNumbersTabsProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  onSendMessage: (numberId: string) => void;
  onDeleteNumber: (numberId: string) => void;
  availableNumbers: AvailablePhoneNumber[];
  setAvailableNumbers: (numbers: AvailablePhoneNumber[]) => void;
}

const PhoneNumbersTabs: React.FC<PhoneNumbersTabsProps> = ({ 
  activeTab, 
  setActiveTab,
  onSendMessage,
  onDeleteNumber,
  availableNumbers,
  setAvailableNumbers
}) => {
  const { user, profile } = useAuth();
  const [myNumbers, setMyNumbers] = useState<PhoneNumber[]>([]);
  const [isLoadingMyNumbers, setIsLoadingMyNumbers] = useState(true);
  const [isLoadingAvailableNumbers, setIsLoadingAvailableNumbers] = useState(false);
  
  // Purchase confirmation dialog state
  const [purchaseConfirmation, setPurchaseConfirmation] = useState({
    isOpen: false,
    numberId: '',
    numberDetails: null as (AvailablePhoneNumber | null),
  });
  
  // Load user's phone numbers
  useEffect(() => {
    const fetchMyNumbers = async () => {
      if (!user) return;
      
      try {
        const { data, error } = await supabase
          .from('phone_numbers')
          .select('*')
          .eq('user_id', user.id);
          
        if (error) throw error;
        
        // Map the returned data to match our PhoneNumber interface
        const typedPhoneNumbers: PhoneNumber[] = (data || []).map(item => ({
          id: item.id,
          number: item.number,
          location: item.location,
          status: item.status === 'active' ? 'active' : 'pending',
          tier: item.tier as PhoneNumberTier,
          monthly_fee: item.monthly_fee,
          purchase_date: item.purchase_date,
          messages_sent: item.messages_sent,
          api_username: item.api_username || undefined,
          api_password: item.api_password || undefined
        }));
        
        setMyNumbers(typedPhoneNumbers);
      } catch (error) {
        console.error('Error fetching phone numbers:', error);
        toast.error('Failed to load your phone numbers');
      } finally {
        setIsLoadingMyNumbers(false);
      }
    };
    
    fetchMyNumbers();
  }, [user]);
  
  // Show confirmation dialog before purchase
  const handlePurchaseClick = (numberId: string) => {
    const numberToPurchase = availableNumbers.find(num => num.id === numberId);
    if (!numberToPurchase) {
      toast.error('Number not found');
      return;
    }

    setPurchaseConfirmation({
      isOpen: true,
      numberId,
      numberDetails: numberToPurchase
    });
  };

  // Close confirmation dialog
  const handleCloseConfirmation = () => {
    setPurchaseConfirmation({
      isOpen: false,
      numberId: '',
      numberDetails: null
    });
  };
  
  // Complete the purchase after confirmation
  const handleConfirmPurchase = async () => {
    const { numberId } = purchaseConfirmation;
    
    if (!user) {
      toast.error('You must be logged in to purchase a number');
      handleCloseConfirmation();
      return;
    }
    
    try {
      // Find the number to purchase
      const numberToPurchase = availableNumbers.find(num => num.id === numberId);
      if (!numberToPurchase) {
        throw new Error('Number not found');
      }
      
      // Check if user has sufficient balance
      if (!profile) {
        throw new Error('Unable to retrieve user profile');
      }
      
      if (profile.balance < numberToPurchase.price) {
        toast.error(`Insufficient balance. You need £${numberToPurchase.price.toFixed(2)} to purchase this number.`);
        handleCloseConfirmation();
        return;
      }
      
      // First, update user's balance (deduct the price)
      const { error: balanceError } = await supabase
        .from('profiles')
        .update({ 
          balance: profile.balance - numberToPurchase.price 
        })
        .eq('id', user.id);
        
      if (balanceError) throw balanceError;
      
      // Update the available_numbers table to mark it as unavailable
      const { error: updateError } = await supabase
        .from('available_numbers')
        .update({ is_available: false })
        .eq('id', numberId);
        
      if (updateError) throw updateError;
      
      // Then insert into the user's phone_numbers
      const { data, error: insertError } = await supabase
        .from('phone_numbers')
        .insert({
          user_id: user.id,
          number: numberToPurchase.number,
          location: numberToPurchase.location,
          tier: numberToPurchase.tier,
          monthly_fee: numberToPurchase.price,
          status: 'active'
        })
        .select()
        .single();
        
      if (insertError) throw insertError;
      
      // Update local state with the properly typed new phone number
      const newPhoneNumber: PhoneNumber = {
        id: data.id,
        number: data.number,
        location: data.location,
        status: 'active',
        tier: data.tier as PhoneNumberTier,
        monthly_fee: data.monthly_fee,
        purchase_date: data.purchase_date,
        messages_sent: data.messages_sent || 0,
        api_username: data.api_username || undefined,
        api_password: data.api_password || undefined
      };
      
      setMyNumbers([...myNumbers, newPhoneNumber]);
      setAvailableNumbers(availableNumbers.filter(num => num.id !== numberId));
      
      toast.success('Phone number purchased successfully');
      setActiveTab('my-numbers');
    } catch (error: unknown) {
      console.error('Error purchasing number:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to purchase phone number');
    } finally {
      handleCloseConfirmation();
    }
  };
  
  // Function to get tier badge class
  const getTierBadgeClass = (tier: PhoneNumberTier) => {
    switch(tier) {
      case 'premium':
        return 'bg-purple-100 text-purple-800';
      case 'gold':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };
  
  return (
    <>
      <MyNumbersTab 
        myNumbers={myNumbers}
        isLoading={isLoadingMyNumbers}
        onSendMessage={onSendMessage}
        onDeleteNumber={onDeleteNumber}
        setActiveTab={setActiveTab}
        getTierBadgeClass={getTierBadgeClass}
      />
      
      <BuyNumbersTab 
        availableNumbers={availableNumbers}
        isLoading={isLoadingAvailableNumbers}
        onPurchaseNumber={handlePurchaseClick}
        getTierBadgeClass={getTierBadgeClass}
      />
      
      {/* Confirmation Dialog */}
      {purchaseConfirmation.numberDetails && (
        <ConfirmationDialog
          isOpen={purchaseConfirmation.isOpen}
          onClose={handleCloseConfirmation}
          onConfirm={handleConfirmPurchase}
          title="Confirm Phone Number Purchase"
          description={`Are you sure you want to purchase ${purchaseConfirmation.numberDetails.number} for £${purchaseConfirmation.numberDetails.price.toFixed(2)}/month? This will be charged monthly to your account.`}
          confirmText="Purchase Number"
          cancelText="Cancel"
        />
      )}
    </>
  );
};

export default PhoneNumbersTabs;
