import React, { useState } from 'react';
import { TabsContent } from '@/components/ui/tabs';
import { Search } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { PhoneNumberPurchaseCard } from '@/components/PhoneNumberCard';
import { Loader2 } from 'lucide-react';
import { PhoneNumberTier } from '@/types/phoneNumbers';
import { formatUKPhoneNumber } from '@/utils/formatPhoneNumber';

interface AvailablePhoneNumber {
  id: string;
  number: string;
  location: string;
  tier: PhoneNumberTier;
  price: number;
  is_available: boolean;
}

interface BuyNumbersTabProps {
  availableNumbers: AvailablePhoneNumber[];
  isLoading: boolean;
  onPurchaseNumber: (numberId: string) => void;
  getTierBadgeClass: (tier: PhoneNumberTier) => string;
}

const BuyNumbersTab: React.FC<BuyNumbersTabProps> = ({
  availableNumbers,
  isLoading,
  onPurchaseNumber,
  getTierBadgeClass
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tierFilter, setTierFilter] = useState<string>('all');
  const [numberTypeFilter, setNumberTypeFilter] = useState<string>('all');
  
  const filteredAvailableNumbers = availableNumbers.filter(number => {
    // Format the number for searching
    const formattedNumber = formatUKPhoneNumber(number.number);
    // Filter by search query (search in both formatted and unformatted number)
    const matchesSearch = formattedNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         number.number.toLowerCase().includes(searchQuery.toLowerCase());
    
    // Filter by tier
    const matchesTier = tierFilter === 'all' || number.tier === tierFilter;
    
    // Filter by number type
    const isMobile = number.number.includes('7700');
    const is0800 = number.number.includes('0800');
    const is01202 = number.number.includes('01202');
    
    let matchesType = true;
    if (numberTypeFilter === 'mobile') {
      matchesType = isMobile;
    } else if (numberTypeFilter === '0800') {
      matchesType = is0800;
    } else if (numberTypeFilter === '01202') {
      matchesType = is01202;
    }
    
    return matchesSearch && matchesTier && matchesType;
  });

  if (isLoading) {
    return (
      <TabsContent value="buy-numbers" className="py-12 text-center">
        <Loader2 className="h-8 w-8 mx-auto animate-spin text-blue-500" />
        <p className="mt-4 text-gray-500">Loading available phone numbers...</p>
      </TabsContent>
    );
  }
  
  return (
    <TabsContent value="buy-numbers" className="space-y-6">
      <div className="flex flex-col md:flex-row items-center gap-4 mb-6">
        <div className="relative flex-1 w-full">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
          <Input
            placeholder="Search for a phone number"
            className="pl-10"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        
        <Select value={tierFilter} onValueChange={setTierFilter}>
          <SelectTrigger className="w-[150px]">
            <SelectValue placeholder="Tier" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Tiers</SelectItem>
            <SelectItem value="standard">Standard</SelectItem>
            <SelectItem value="premium">Premium</SelectItem>
            <SelectItem value="gold">Gold</SelectItem>
          </SelectContent>
        </Select>
        
        <Select value={numberTypeFilter} onValueChange={setNumberTypeFilter}>
          <SelectTrigger className="w-[150px]">
            <SelectValue placeholder="Number Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Types</SelectItem>
            <SelectItem value="mobile">Mobile</SelectItem>
            <SelectItem value="0800">0800</SelectItem>
            <SelectItem value="01202">01202</SelectItem>
          </SelectContent>
        </Select>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAvailableNumbers.map((number) => (
          <PhoneNumberPurchaseCard
            key={number.id}
            number={formatUKPhoneNumber(number.number)}
            location={number.location}
            availability="Immediate"
            price={`£${number.price.toFixed(2)}/month`}
            onPurchase={() => onPurchaseNumber(number.id)}
            tier={number.tier}
            tierBadgeClass={getTierBadgeClass(number.tier)}
          />
        ))}
      </div>
      
      {filteredAvailableNumbers.length === 0 && (
        <div className="text-center py-12">
          <p className="text-gray-500">No phone numbers match your search criteria</p>
        </div>
      )}
    </TabsContent>
  );
};

export default BuyNumbersTab;
