import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';

interface AccountInfoProps {
  balance: string;
  activeNumbers: number;
  messagesSent: number;
  nextBillingDate: string;
  onBuyCredits: () => void;
  isLoadingCredits?: boolean;
}

const AccountInfo = ({ 
  balance, 
  activeNumbers, 
  messagesSent, 
  nextBillingDate,
  onBuyCredits,
  isLoadingCredits = false
}: AccountInfoProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Account Information</CardTitle>
        <CardDescription>Manage your account balance and view usage</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex items-center justify-between p-4 bg-blue-50 rounded-lg">
          <div>
            <div className="text-sm text-blue-600 font-medium">Current Balance</div>
            <div className="text-2xl font-bold">{balance}</div>
          </div>
          <Button
            onClick={onBuyCredits}
            disabled={isLoadingCredits}
            className="bg-green-600 hover:bg-green-700 text-white"
          >
            {isLoadingCredits ? (
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
            ) : null}
            Buy SMS Credits
          </Button>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="p-4 border rounded-lg">
            <div className="text-sm text-gray-500">Active Numbers</div>
            <div className="text-xl font-semibold mt-1">{activeNumbers}</div>
          </div>
          <div className="p-4 border rounded-lg">
            <div className="text-sm text-gray-500">Messages Sent</div>
            <div className="text-xl font-semibold mt-1">{messagesSent}</div>
          </div>
          <div className="p-4 border rounded-lg">
            <div className="text-sm text-gray-500">Next Billing Date</div>
            <div className="text-xl font-semibold mt-1">{nextBillingDate}</div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AccountInfo;
