import React from 'react';
import { Button } from '@/components/ui/button';
import { ChevronRight, MessageSquare, Phone, Shield, Globe, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className="relative overflow-hidden bg-white">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-blue-50 z-[-1]" />
      
      <div className="container px-4 mx-auto pt-24 pb-16">
        <div className="flex flex-col items-center text-center max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 text-gray-900 animate-in">
            UK Phone Numbers & <br className="hidden md:block" />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-800">SMS Services</span>
          </h1>
          
          <p className="text-lg md:text-xl text-gray-600 mb-8 max-w-2xl animate-up" style={{ animationDelay: '0.1s' }}>
            Simple, transparent pricing with no contracts. Just pay for what you use when you need it.
          </p>
          
          <div className="flex flex-col sm:flex-row gap-4 animate-up" style={{ animationDelay: '0.2s' }}>
            <Link to="/auth?type=signup">
              <Button size="lg" className="rounded-lg bg-blue-600 text-white hover:bg-blue-700 h-12 px-8 shadow-lg shadow-blue-600/20">
                Start Sending SMS Today <ChevronRight className="ml-2 h-4 w-4" />
              </Button>
            </Link>
            <Link to="/api-docs">
              <Button variant="outline" size="lg" className="rounded-lg border-blue-200 text-blue-600 hover:bg-blue-50 h-12 px-8">
                Explore API
              </Button>
            </Link>
          </div>
          
          <div className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl px-8 py-6 mt-12 animate-up shadow-lg shadow-blue-600/20 group hover:shadow-xl hover:-translate-y-1 transition-all duration-300" style={{ animationDelay: '0.3s' }}>
            <div className="flex flex-col items-center">
              <div className="bg-white/10 backdrop-blur-sm px-4 py-1 rounded-full mb-3">
                <p className="text-white text-sm font-medium">🎉 Launch Special Offer</p>
              </div>
              <h3 className="text-2xl md:text-3xl font-bold text-white mb-2 text-center">
                Top up £20 today
              </h3>
              <p className="text-xl text-blue-100 font-medium mb-1 text-center">
                Get <span className="text-white">2 months line rental FREE!</span>
              </p>
              <p className="text-blue-100 text-sm">Limited time offer - Start saving today</p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Quick features grid */}
      <div className="container px-4 mx-auto pb-20" id="quick-features">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-8 rounded-xl flex flex-col items-center text-center transform hover:-translate-y-1 transition-all duration-300 hover:shadow-2xl border border-blue-100 bg-gradient-to-b from-white to-blue-50/50">
            <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-blue-600 to-blue-700 flex items-center justify-center mb-6 shadow-lg shadow-blue-600/20">
              <Phone className="h-7 w-7 text-white" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-900">UK Mobile & Landline Numbers</h3>
            <p className="text-gray-600">Choose from mobile (07), local area codes (01202), and freephone (0800) numbers.</p>
          </div>
          
          <div className="bg-white p-8 rounded-xl flex flex-col items-center text-center transform hover:-translate-y-1 transition-all duration-300 hover:shadow-2xl border border-blue-100 bg-gradient-to-b from-white to-blue-50/50">
            <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-blue-600 to-blue-700 flex items-center justify-center mb-6 shadow-lg shadow-blue-600/20">
              <MessageSquare className="h-7 w-7 text-white" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-900">Simple SMS Messaging</h3>
            <p className="text-gray-600">Easy-to-use dashboard and API for sending text messages at just 10p per message.</p>
          </div>
          
          <div className="bg-white p-8 rounded-xl flex flex-col items-center text-center transform hover:-translate-y-1 transition-all duration-300 hover:shadow-2xl border border-blue-100 bg-gradient-to-b from-white to-blue-50/50">
            <div className="w-14 h-14 rounded-2xl bg-gradient-to-br from-blue-600 to-blue-700 flex items-center justify-center mb-6 shadow-lg shadow-blue-600/20">
              <Zap className="h-7 w-7 text-white" />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-gray-900">No Long Contracts</h3>
            <p className="text-gray-600">Pay monthly with no long-term commitments. Easy to scale as your business grows.</p>
          </div>
        </div>
      </div>
      
      {/* Pricing section */}
      <div className="container px-4 mx-auto pb-20" id="pricing">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Simple, Transparent Pricing</h2>
          <p className="text-gray-600">Choose the phone number plan that works for you</p>
        </div>
        
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white rounded-xl p-8 border border-gray-200 shadow-lg hover:shadow-xl transition-all duration-300">
              <div className="font-semibold text-gray-900 mb-2">Standard</div>
              <div className="text-4xl font-bold text-blue-600 mb-1">£2<span className="text-xl text-gray-500">/mo</span></div>
              <div className="text-gray-600 text-sm mb-6">Mobile numbers</div>
              <div className="border-t border-gray-100 my-6"></div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-700">
                  <Shield className="h-5 w-5 text-blue-600 mr-3" />
                  UK mobile numbers (07)
                </li>
                <li className="flex items-center text-gray-700">
                  <MessageSquare className="h-5 w-5 text-blue-600 mr-3" />
                  10p per SMS
                </li>
                <li className="flex items-center text-gray-700">
                  <Globe className="h-5 w-5 text-blue-600 mr-3" />
                  API access
                </li>
                </ul>
             
              </div>
              
            <div className="bg-white rounded-xl p-8 border-2 border-blue-600 shadow-xl hover:shadow-2xl transition-all duration-300 relative">
              <div className="absolute -top-4 right-8 bg-blue-600 text-white text-sm px-4 py-1 rounded-full font-medium">POPULAR</div>
              <div className="font-semibold text-gray-900 mb-2">Premium</div>
              <div className="text-4xl font-bold text-blue-600 mb-1">£5<span className="text-xl text-gray-500">/mo</span></div>
              <div className="text-gray-600 text-sm mb-6">Freephone numbers</div>
              <div className="border-t border-gray-100 my-6"></div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-700">
                  <Shield className="h-5 w-5 text-blue-600 mr-3" />
                  UK freephone (0800)
                </li>
                <li className="flex items-center text-gray-700">
                  <MessageSquare className="h-5 w-5 text-blue-600 mr-3" />
                  10p per SMS
                </li>
                <li className="flex items-center text-gray-700">
                  <Globe className="h-5 w-5 text-blue-600 mr-3" />
                  API access
                </li>
                <li className="flex items-center text-gray-700">
                  <Zap className="h-5 w-5 text-blue-600 mr-3" />
                  Priority support
                </li>
                </ul>
              
            </div>
            
            <div className="bg-white rounded-xl p-8 border border-gray-200 shadow-lg hover:shadow-xl transition-all duration-300">
              <div className="font-semibold text-gray-900 mb-2">Gold</div>
              <div className="text-4xl font-bold text-blue-600 mb-1">£10<span className="text-xl text-gray-500">/mo</span></div>
              <div className="text-gray-600 text-sm mb-6">Premium numbers</div>
              <div className="border-t border-gray-100 my-6"></div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center text-gray-700">
                  <Shield className="h-5 w-5 text-blue-600 mr-3" />
                  Premium UK numbers
                </li>
                <li className="flex items-center text-gray-700">
                  <MessageSquare className="h-5 w-5 text-blue-600 mr-3" />
                  10p per SMS
                </li>
                <li className="flex items-center text-gray-700">
                  <Globe className="h-5 w-5 text-blue-600 mr-3" />
                  Advanced API features
                </li>
                <li className="flex items-center text-gray-700">
                  <Zap className="h-5 w-5 text-blue-600 mr-3" />
                  24/7 support
                </li>
              </ul>
              
            </div>
          </div>
          
          <div className="bg-gradient-to-r from-blue-50 to-blue-100 rounded-xl mt-20 p-8 text-center">
              <div className="mb-6 md:mb-0">
                <h3 className="text-xl font-bold text-blue-900 mb-2">Pay As You Go SMS</h3>
                <p className="text-blue-700">Just 10p per text message - only pay for what you use.</p>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;