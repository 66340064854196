import React from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import { Building2, Users, Shield, Globe } from 'lucide-react';

const About = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />

      <main className="flex-grow">
        <div className="bg-white py-16 sm:py-24">
          <div className="container px-4 mx-auto">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">About EasySMS</h1>
              <p className="text-xl text-gray-600">
                We're building the future of business communications in the UK
              </p>
            </div>
            
            <div className="max-w-4xl mx-auto">
              <div className="prose prose-lg">
                <p>
                  EasySMS is a UK-based technology company focused on making business communications simple and accessible. 
                  We provide virtual phone numbers and SMS messaging services to businesses of all sizes, from startups to enterprises.
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-12">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Building2 className="h-8 w-8 text-blue-600" />
              </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-semibold text-gray-900">UK Based</h3>
                      <p className="text-gray-600">Headquartered in London, serving businesses across the United Kingdom</p>
          </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Shield className="h-8 w-8 text-blue-600" />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-semibold text-gray-900">Secure & Compliant</h3>
                      <p className="text-gray-600">Following industry best practices for security and data protection</p>
                </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Users className="h-8 w-8 text-blue-600" />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-semibold text-gray-900">Customer Focused</h3>
                      <p className="text-gray-600">Dedicated to providing excellent support and service</p>
                </div>
                  </div>
                  
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <Globe className="h-8 w-8 text-blue-600" />
                </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-semibold text-gray-900">Scalable Solutions</h3>
                      <p className="text-gray-600">Built to grow with your business needs</p>
              </div>
            </div>
          </div>
                
                <p>
                  Our mission is to make professional communication tools accessible to businesses of all sizes. 
                  We believe in transparent pricing, excellent customer service, and building long-term relationships with our clients.
                </p>
              </div>
              </div>
            </div>
          </div>
      </main>

      <Footer />
    </div>
  );
};

export default About;
