import React from 'react';
import { CheckCircle, Globe, Lock, Smartphone, Zap, Headphones, Server } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }: { icon: React.ElementType, title: string, description: string }) => {
  return (
    <div className="glass-card p-6 rounded-2xl transition-all duration-300 hover:shadow-md hover:-translate-y-1">
      <div className="mb-4 text-blue-600">
        <Icon size={28} strokeWidth={1.5} />
      </div>
      <h3 className="text-xl font-medium mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const Features = () => {
  const features = [
    {
      icon: Smartphone,
      title: 'UK Mobile Numbers',
      description: 'Get your own 07 mobile number for your business at just £2/month.'
    },
    {
      icon: Headphones,
      title: 'Freephone Numbers',
      description: 'Professional 0800 numbers to enhance your business presence from £5/month.'
    },
    {
      icon: Globe,
      title: 'Local Area Codes',
      description: 'Build local presence with 01202 and other area code numbers from £3/month.'
    },
    {
      icon: Server,
      title: 'Simple API',
      description: 'Easy-to-use REST API for integrating messaging into your applications.'
    }
  ];

  const benefits = [
    'Start using your number immediately after top-up',
    'Monthly billing with no long-term contracts',
    'Create separate numbers for different campaigns',
    'Simple pricing: just 10p per SMS message',
    'UK-based customer support',
    'Compliant with UK regulations'
  ];

  return (
    <section id="features" className="py-10 bg-gradient-to-b from-white to-blue-50">
      <div className="container px-4 mx-auto">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-semibold mb-4">Why Choose EasySMS?</h2>
          <p className="text-xl text-gray-600">
            We're a new UK-based startup providing affordable virtual phone numbers with no hidden fees.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-20">
          {features.map((feature, index) => (
            <FeatureCard 
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>

        <div className="glass-card rounded-3xl overflow-hidden shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="p-8 md:p-12 flex flex-col justify-center">
              <h3 className="text-2xl font-medium mb-6">Benefits That Matter</h3>
              <div className="space-y-4">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-start">
                    <CheckCircle className="h-5 w-5 text-blue-600 mr-3 flex-shrink-0 mt-0.5" />
                    <span className="text-gray-800">{benefit}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-blue-600 p-8 md:p-12 text-white flex flex-col justify-center">
              <h3 className="text-2xl font-medium mb-6">Launch Special Offer</h3>
              <div className="space-y-6">
                <div>
                  <div className="text-3xl font-bold mb-1">FREE 2 MONTHS</div>
                  <p className="text-blue-100">Top up £20 and get your first two months line rental completely free!</p>
                </div>
                <div>
                  <div className="text-xl font-bold flex items-center">
                    <span className="text-sm font-normal mr-1">Save up to</span>
                    <span>£20</span>
                  </div>
                  <p className="text-blue-100">Depending on your chosen plan</p>
                </div>
                <div className="bg-white/10 backdrop-blur-sm p-4 rounded-lg mt-4">
                  <p className="text-sm text-blue-50">
                    <span className="font-medium">Limited Time:</span> Get started today to take advantage of this special launch offer!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;